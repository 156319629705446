export const CONFIG_PERMISSIONS = {
  football: 1,
  tennis: 2,
  cricket: 4,
  live_casino: 8,
  indian_casino: 16,
  sports: 7,
  casino: 24,
  commission: 32,
  crypto_currency: 64,
  binary: 128,
};
