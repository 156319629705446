export const ADD_EXCH_BET = 'ADD_EXCH_BET';
export const REMOVE_EXCH_BET = 'REMOVE_EXCH_BET';
export const SET_EXCH_BET_STAKE = 'SET_EXCH_BET_STAKE';
export const CLEAR_EXCHANGE_BETS = 'CLEAR_EXCHANGE_BETS';
export const EXCH_BET_ODDS_CHANGE = 'EXCH_BET_ODDS_CHANGE';
export const FETCH_OPEN_BETS_SUCCESS = 'FETCH_OPEN_BETS_SUCCESS';
export const ADD_OPEN_BETS = 'ADD_OPEN_BETS';
export const CLEAR_SUCCESS_BETS = 'CLEAR_SUCCESS_BETS';
export const SET_BUTTON_VARIABLES = 'SET_BUTTON_VARIABLES';
export const SET_BETTING_INPROGRESS = 'SET_BETTING_INPROGRESS';
export const BET_CANCEL_SUCCESS = 'BET_CANCEL_SUCCESS';
export const ENABLE_ONE_CLICK_BETS = 'ENABLE_ONE_CLICK_BETS';
export const ADD_ONE_CLICK_AMOUNT = 'ADD_ONE_CLICK_AMOUNT';
export const UPDATE_BET_SLIP = 'UPDATE_BET_SLIP';
export const RESET_BETSLIP_ODDS_CHANGE_MSG = 'RESET_BETSLIP_ODDS_CHANGE_MSG';
export const VALIDATE_BETSLIP_ODDS = 'VALIDATE_BETSLIP_ODDS';
export const FETCH_BET_STATUS_IN_PROGRESS = 'FETCH_BET_STATUS_IN_PROGRESS';
export const FETCH_BET_STATUS_SUCCESS = 'FETCH_BET_STATUS_SUCCESS';
export const FETCH_BET_STATUS_FAIL = 'FETCH_BET_STATUS_FAIL';
export const CLEAR_BET_STATUS_RESPONSE = 'CLEAR_BET_STATUS_RESPONSE';
