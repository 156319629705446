import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { connect } from 'react-redux';
import { Switch } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */

/* Theme variables */
import './App.scss';
import { RootState } from './models/RootState';
import './theme/variables.css';

import { HelmetProvider } from 'react-helmet-async';
import { setTimeout } from 'timers';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import { fetchMailVerifiedSuccess, logout } from './store';
const SignUp = lazy(() => import('./pages/SignUp/SignUp'));
const LoginPage = lazy(() => import('./pages/Login/LoginPage'));
const ResetPassword = lazy(
  () => import('./pages/ResetPasswordPage/ResetPasswordPage')
);
const AccessRedirect = lazy(
  () => import('./pages/AccessRedirect/Accessredirect')
);
const AcceptTerms = lazy(() => import('./pages/AcceptTerms/AcceptTerms'));
const ForgotPwdForm = lazy(
  () => import('./components/ForgotPassword/ForgotPassword')
);
const MainPage = lazy(() => import('./router/UserRouter'));

type StateProps = {
  fetchMailVerifiedSuccess: (mailVerified: boolean) => void;
  logout: () => void;
  mailVerified: boolean;
  prefersDark: string;
  loggedIn: boolean;
};

const App: React.FC<StateProps> = (props) => {
  const consoleOpen = useConsoleOpen();
  const helmetContext = {};
  // const consoleOpen = false;

  const { logout, prefersDark, loggedIn } = props;

  useEffect(() => {
    const selectedTheme = localStorage.getItem('userTheme');
    if (selectedTheme) {
      let a = ['darkgreen', 'purple', 'darkvoilet'];
      a.splice(a.indexOf(prefersDark), 1);
      document.body.classList.remove(...a);
      document.body.classList.toggle(prefersDark, true);
    } else {
      localStorage.setItem('userTheme', 'darkgreen');
      document.body.classList.add('darkgreen');
    }
  }, [prefersDark]);

  const handleOnIdle = () => {
    console.log('user is idle');
    if (loggedIn) {
      logout();
    }
  };

  const handleClose = () => {
    console.log('user open dev tools');
  };

  useIdleTimer({
    timeout: 1000 * 60 * 60 * 2, // 2 hours
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <>
      <HelmetProvider context={helmetContext}>
        {!consoleOpen ? (
          <Suspense fallback={<LoadingPage />}>
            <BrowserRouter>
              <Switch>
                <Route path="/access-redirect" component={AccessRedirect} />
                <Route path="/terms-and-conditions" component={AcceptTerms} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/login" component={LoginPage} />
                <Route path="/register" component={SignUp} />
                <Route path="/forgot-password" component={ForgotPwdForm} />
                <Route path="/" component={MainPage} />
              </Switch>
            </BrowserRouter>
          </Suspense>
        ) : null}
      </HelmetProvider>

      <Dialog
        open={consoleOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        className="dev-tools-msg-modal"
      >
        <DialogContent className="modal-content-ctn">
          <div className="dev-tools-warning-msg">
            {' '}
            Due to some inactivity or security reasons stop your website, please
            close the developer tool. Thank you for your support
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const useConsoleOpen = () => {
  const [consoleOpen, setConsoleOpen] = useState(false);

  useEffect(() => {
    let checkStatus;

    var element = new Image();
    Object.defineProperty(element, 'id', {
      get: function () {
        checkStatus = true;
        throw new Error('Dev tools checker');
      },
    });
    requestAnimationFrame(function check() {
      setTimeout(() => {
        checkStatus = false;
        // Don't delete this console statements
        // Uncomment fater fixing ios issues
        // if (process.env.REACT_APP_NODE_ENV !== 'development') {
        //   console.dir(element);
        //   console.clear();
        // }
        setConsoleOpen(checkStatus);
        requestAnimationFrame(check);
      }, 1000);
    });
  });

  return consoleOpen;
};

const mapStateToProps = (state: RootState) => {
  return {
    mailVerified: state.auth.mailVerified,
    prefersDark: state.common.prefersDark,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchMailVerifiedSuccess: (mailVerified: boolean) =>
      dispatch(fetchMailVerifiedSuccess(mailVerified)),
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
